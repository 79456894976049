<template>
  <div>
      <b-loading :is-full-page="true" :active="totalQuestionsCount===0"></b-loading>
      <div class="card is-shadowless" style="border: 2px dashed rgba(0,0,0,0.13);">
        <div class="card-content">
          <div class="columns">
            <b-field grouped group-multiline class="column">
              <div class="control" v-if="$api.canEdit()">
                <button class="button is-primary" style="top: -8px;" @click="create" >{{
                    $t('questions.addNewQuestion')
                  }}
                </button>
              </div>
              <b-pagination
                  :total="totalQuestionsCount"
                  v-model="currentPage"
                  :icon-prev="prevIcon"
                  :icon-next="nextIcon"
                  :per-page="50"
                  :simple="true"
                  @change="load"
                  class="questions-pagination"
              >
              </b-pagination>
            </b-field>
          </div>

          <b-table :data="filteredQuestions">

            <b-table-column field="id" label="ID" width="40" numeric v-slot="props">
              {{ props.row.id }}
            </b-table-column>

            <b-table-column field="category" :label="$tc('questions.category')" v-slot="props">
              {{ props.row.category }}
            </b-table-column>

            <b-table-column field="code" :label="$tc('questions.code')" v-slot="props">
              {{ props.row.code }}
            </b-table-column>

            <b-table-column field="language" :label="$tc('questions.language')" v-slot="props">
              {{ props.row.language }}
            </b-table-column>

            <b-table-column field="question_text" :label="$tc('questions.question')" v-slot="props" width="40%">
              {{ props.row.question_text }}
            </b-table-column>

            <b-table-column :label="$tc('questions.question')" v-slot="props">
              <button class="button is-warning is-small mr-2" @click="edit(props.row)">{{ $t('questions.edit') }}
              </button>
              <button v-if="$api.canEdit()" class="button is-danger is-small" @click="deleteQuestion(props.row)">{{ $t('questions.destroy') }}
              </button>
            </b-table-column>

            <template #empty>
              <div class="has-text-centered">{{ $t('questions.noData') }}</div>
            </template>

          </b-table>

          <b-modal
              :active="showEditingModal"
              has-modal-card
              trap-focus
              :destroy-on-hide="false"
              aria-role="dialog"
              aria-label="Modal"
              close-button-aria-label="Close"
              @close="showEditingModal = false"
              aria-modal>
            <div class="card" v-if="selectedQuestion!=null">
              <div class="card-content questions-modal-card"
                   style="padding-right: 24px;  max-width: 960px; overflow-y: scroll; max-height: 90vh">
                <h2 class="title" v-if="selectedQuestion.id!==-1">Pytanie #{{ selectedQuestion.id }}</h2>
                <h2 class="title" v-if="selectedQuestion.id===-1">Nowe pytanie</h2>
                <div class="columns">
                  <div class="column">
                    <b-field grouped group-multiline>
                      <div class="control" style="width: 100%">
                        <b-field :label="$tc('questions.category')">
                          <b-input :placeholder="$tc('questions.category')" v-model="selectedQuestion.category"/>
                        </b-field>
                      </div>
                      <div class="control" style="width: 100%">
                        <b-field :label="$tc('questions.code')">
                          <b-input placeholder="Kod" v-model="selectedQuestion.code"/>
                        </b-field>
                      </div>
                      <div class="control" style="width: 100%">
                        <b-field label="Język">
                          <b-select :placeholder="$tc('questions.language')" class="control" :expanded="true"
                                    v-model="selectedQuestion.language">-->
                            <option value="pl">{{ $t('languages.polish') }}</option>
                            <option value="en">{{ $t('languages.english') }}</option>
                          </b-select>
                        </b-field>
                      </div>
                      <div class="control" style="width: 100%;">
                        <b-field label="Poprawne odpowiedź">
                          <b-checkbox v-model="selectedQuestion.answer_a_status"
                                      :true-value="1"
                                      :false-value="0">
                            {{ $t('questions.answerA') }}
                          </b-checkbox>
                          <b-checkbox v-model="selectedQuestion.answer_b_status"
                                      :true-value="1"
                                      :false-value="0">
                            {{ $t('questions.answerB') }}
                          </b-checkbox>
                          <b-checkbox v-model="selectedQuestion.answer_c_status"
                                      :true-value="1"
                                      :false-value="0">
                            {{ $t('questions.answerC') }}
                          </b-checkbox>
                          <b-checkbox v-model="selectedQuestion.answer_d_status"
                                      :true-value="1"
                                      :false-value="0">
                            {{ $t('questions.answerD') }}
                          </b-checkbox>
                          <!--                          <b-select :placeholder="$tc('questions.correctAnswer')" class="control" :expanded="true"-->
                          <!--                                    v-model="selectedQuestion.correct_answer">&ndash;&gt;-->
                          <!--                            <option value="a">{{ $t('questions.answerA') }}</option>-->
                          <!--                            <option value="b">{{ $t('questions.answerB') }}</option>-->
                          <!--                            <option value="c">{{ $t('questions.answerC') }}</option>-->
                          <!--                            <option value="d">{{ $t('questions.answerD') }}</option>-->
                          <!--                          </b-select>-->
                        </b-field>
                      </div>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field :label="$tc('questions.content')">
                      <b-input :placeholder="$tc('questions.content')" class="control"
                               v-model="selectedQuestion.question_text"
                               type="textarea"/>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field :label="$tc('questions.answerA')">
                      <b-input :placeholder="$tc('questions.answerA')" class="control"
                               v-model="selectedQuestion.answer_a_text"
                               type="textarea"/>
                    </b-field>
                    <b-field :label="$tc('questions.answerC')">
                      <b-input :placeholder="$tc('questions.answerC')" class="control"
                               v-model="selectedQuestion.answer_c_text"
                               type="textarea"/>
                    </b-field>

                  </div>
                  <div class="column">
                    <b-field :label="$tc('questions.answerB')">
                      <b-input :placeholder="$tc('questions.answerB')" class="control"
                               v-model="selectedQuestion.answer_b_text"
                               type="textarea"/>
                    </b-field>
                    <b-field :label="$tc('questions.answerD')">
                      <b-input :placeholder="$tc('questions.answerD')" class="control"
                               v-model="selectedQuestion.answer_d_text"
                               type="textarea"/>
                    </b-field>

                  </div>
                </div>
                <div class="columns" v-if="$api.canEdit()">
                  <div class="column" style="float: left">
                    <button class="button is-danger" @click="deleteQuestion(selectedQuestion)">
                      {{ $t('questions.destroy') }}
                    </button>
                  </div>
                  <div class="column" style="float: right" align="right">
                    <button class="button is-primary is-inverted mr-2" @click="showEditingModal = false">
                      {{ $t('questions.cancel') }}
                    </button>
                    <button class="button is-success" @click="save"
                            :disabled="selectedQuestion.question_text.length===0">
                      {{ $t('questions.save') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </b-modal>
        </div>
      </div>
  </div>
</template>

<script>
import HomeWrapper from "../components/HomeWrapper";
import MD5 from "../utils/md5";

export default {
  name: "Questions",
  components: {HomeWrapper},
  data() {
    return {
      questions: [],
      textFilter: '',
      showEditingModal: false,
      selectedQuestion: null,
      currentPage: 1,
      totalQuestionsCount: 0,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right'
    };
  },
  async mounted() {
    await this.load();
  },
  computed: {
    filteredQuestions() {
      if (this.textFilter.toString().length > 0) return this.questions.filter(x => Object.values(x).join('').toLowerCase().includes(this.textFilter.toLowerCase()));
      return this.questions;
    }
  },
  methods: {
    async load() {
      const paginatedQuestions = await this.$api.getQuestions(this.currentPage);
      this.totalQuestionsCount = paginatedQuestions.total;
      this.questions = paginatedQuestions.data;
    },
    edit(question) {
      this.selectedQuestion = Object.assign({}, question, {});
      this.showEditingModal = true;
    },
    md5(input) {
      return MD5(input);
    },
    async save() {
      if (this.selectedQuestion.id === -1) {
        const question = await this.$api.createQuestion(this.selectedQuestion);
        this.selectedQuestion.id = question.id;
        await this.$api.editQuestion(this.selectedQuestion);
        this.showEditingModal = false;
        await this.load();
      } else {
        await this.$api.editQuestion(this.selectedQuestion);
        this.showEditingModal = false;
        await this.load();
      }
    },
    create() {
      this.selectedQuestion = {
        id: -1,
        question_text: '',
        answer_a_text: '',
        answer_b_text: '',
        answer_c_text: '',
        answer_d_text: '',
      };
      this.showEditingModal = true;
    },
    deleteQuestion(question) {
      this.$buefy.snackbar.open({
        message: `Jesteś pewien usunięcia pytania?`,
        type: 'is-danger',
        position: 'is-top',
        actionText: 'Usuń teraz!',
        indefinite: true,
        onAction: async () => {
          await this.$api.deleteQuestion(question);
          this.showEditingModal = false;
          await this.load();
        }
      })
    }
  }
}
</script>

<style scoped>
@media (min-width: 768px) {
  .questions-pagination {
    margin-top: -26px;
  }
}

@media (min-width: 1400px) {
  .questions-modal-card {
    width: 50vw;
  }
}

@media (max-width: 768px) {
  .questions-pagination {
    margin-top: -18px;
  }

  .questions-modal-card {
    max-height: 85vh;
  }
}
</style>
